@import './variable.scss';

.language-switcher-container {
    position: relative;
    display: inline-block;

    .lang-button {
        &:focus {
            background: rgba(0, 0, 0, 0.1);
            outline: 1px solid black;
        }
    }

}

.lang-button {
    border: 2px solid $primary;
    background: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: background 0.2s ease,
        border 0.2s ease;
    justify-content: center;

    @media screen and (max-width:768px) {
        padding: 5px 6px;
    }
}

.lang-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.lang-button:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.04);
}

.lang-button.active {
    background: rgba(0, 0, 0, 0.08);
}

.caret-icon {
    width: 0;
    height: 0;
    margin-left: 6px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000;
    transition: transform 0.2s ease;
}

.caret-icon.open {
    transform: rotate(180deg);
}

/* iOS-like menu styling */
.lang-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.08);
    padding: 0;
    z-index: 999;
    border: 1px solid #ccc;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    white-space: nowrap;
}

.lang-option {
    background: #fff;
    border: none;
    padding: 6px 8px;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    color: #333;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    transition: background 0.2s ease;
    font-weight: 400;
    min-width: 60px;
    text-align: center;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    &+.lang-option {
        border-top: 1px solid #eee;
    }
}


.lang-option.active {
    background: rgba(0, 0, 0, 0.1);
}

.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
}

.lang-error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}