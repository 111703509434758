.sidebar {
  width: 300px;
  position: absolute;
  top: 0;
  transition: all 0.1s ease-in-out;
  transform: translateX(-100%);
  display: none;
  height: 100vh;
  z-index: 999999;
}

.sidebar-container.active__1 .sidebar {
  opacity: 1;
  /* visibility: visible; */
  position: fixed;
  transition: all 0.1s ease-in-out;
  transform: translateX(0);
  z-index: 10000000;
  width: 55%;
  height: 100vh;
  box-shadow: 0 0 15px rgba(0 0 0 / 15%);
}

/* .active__1 {
  opacity: 1;
  position: fixed;
  transition: all 0.1s ease-in-out;
  transform: translateX(0);
  z-index: 10000000;
  width: 55%;
  height: 100vh;
  box-shadow: 0 0 15px rgba(0 0 0 / 15%);
} */

.anchor {
  color: #fff !important;
  font-size: 1rem;
  text-decoration: none;
  margin: 1rem;
  border-bottom: gray 1px solid;
  padding-bottom: 1rem;
}

.sidebar-container.active__1 .sidebar-backdrop {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 110vw;
  background: rgba(0, 0, 0, 0.7);
  transition: transform 0.01s ease-in-out;
}
