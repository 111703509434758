.sign-in__container {
  min-height: fit-content;
}

.sign-in__title {
  color: #14328c;
  /* font-family: "Mulish"; */
  font-family: "Varela Round";
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  /* text-transform: uppercase; */
}

.sign-in__title-2 {
  color: #14328c;
  /* font-family: "Mulish"; */
  font-family: "Varela Round";
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  /* text-transform: uppercase; */
}

.btn-signin {
  height: 50px;
  color: #fff !important;
  background: #14328c !important;
  font-family: "Mulish";
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}

.btn-forgot-pwd {
  color: #14328c;
  font-family: Mulish;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: underline;
  font-weight: 700 !important;
}

@media (min-width: 700px) {
  .sign-in__container {
    min-height: calc(100vh - 200px);
  }

  .sign-in__title {
    font-size: 55px;
    line-height: 70px;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .sign-in__title {
    line-height: 70px;
    text-align: left;
  }
}


@media (min-width: 1024px) and (height: 1366px) {
  .sign-in__container {
    min-height: calc(100vh - 590px);
  }
}

@media (min-width: 1900px) {
  .sign-in__container {
    min-height: calc(100vh - 542px);
  }
}