.my-step-form {
	width: 100%;
}
.my-step-form > div {
	margin-bottom: 20px;
}
.my-step-container {
	width: 100%;
}
.svg-icon {
	width: 1em;
	height: 1em;
}
.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
	fill: #ffffff;
}
.svg-icon circle {
	stroke: #4691f6;
	stroke-width: 1;
}
.rc-steps-label-vertical .rc-steps-item-tail {
	padding: 0px 7px !important;
	margin-left: 69px !important;
}
.rc-steps-item-icon {
	width: 50px !important;
	height: 50px !important;
	border: 2px solid #d6d6d6 !important;
	font-size: 16px;
	font-family: "Mulish";
	font-weight: 300;
}
.rc-steps-item-icon > .rc-steps-icon {
	top: 10px !important;
}
.rc-steps-item-tail {
	top: 25px !important;
}
.rc-steps-item-finish .rc-steps-item-icon {
	border-color: #fff !important;
	background-color: #d6d6d6 !important;
}
.svg-icon {
	width: 48px !important;
	height: 21px !important;
}
.rc-steps-item-process .rc-steps-item-icon {
	background: #14328c !important;
}
.rc-steps-item-tail:after {
	height: 3px !important;
}
.rc-steps-item-process .rc-steps-item-icon {
	border-color: #14328c !important;
}
.rc-steps-item-finish .rc-steps-item-tail:after {
	background-color: #d6d6d6 !important;
}
.rc-steps-label-vertical .rc-steps-item-icon {
	margin-left: 26px !important;
}
.rc-steps-item-title {
	font-size: 16px !important;
	font-family: "Mulish" !important;
}
.rc-steps-item-process .rc-steps-item-title {
	color: #14328c !important;
	font-weight: 700 !important;
}
.rc-steps-item-finish .rc-steps-item-title {
	font-family: "Mulish" !important;
	font-weight: 300 !important;
	color: #000000 !important;
}
.rc-steps-item-wait .rc-steps-item-title {
	font-family: "Mulish" !important;
	font-weight: 300 !important;
	color: #000000 !important;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
	color: #000 !important;
}