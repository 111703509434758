.container-prs {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .info-logo{
    cursor: pointer;
    position: relative;
  }



  .ps-box{
    display: none;
    position: absolute;
    transform: translate(-60%, -50%);
    top: -106px;
    left: 40px;
}

.pir-box{
  display: none;
  position: absolute;
  transform: translate(-60%, -50%);
  top: -170px;
  left: -405px;
}


  .i-icon:hover + .ps-box{
    display: block;
  }

  .i-icon:hover + .pir-box{
    display: block;
  }

  .bt-line {
    width: 100%;
    border-bottom: 1px solid#d9dada;
    line-height: 24px;
  }

  .mg-t {
    margin-top: 38px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:  1fr ;
  }

body:has(.container-prs) .popup-content{
  width: 400px !important;
  border: 2px solid black !important;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;

}


body:has(.container-prs) .popup-arrow{
  stroke: black !important;
  stroke-dasharray: 35px !important;
  stroke-dashoffset: -56px !important;
  display: inline-block ;
  width: 37px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: rotate(-90deg) translateY(-150%) translateX(25%);
}



div:has(.pir-content)  .popup-arrow-2{
  stroke: black !important;
  stroke-dasharray: 38px !important;
  stroke-dashoffset: -57px !important;
  display: inline-block ;
  width: 37px;
  position: absolute;
  stroke-width: 2px;
  top: 45%;
  left:90%;
  right: 90%;
  transform: rotate(90deg) translateY(-150%) translateX(25%);
  color: white;

}


body:has(.container-prs) .popup-content p {
  background-color: #ffffff;
  color: #000000;
  padding-right: 8px;
  padding-left:8px;
}
body:has(.container-prs) .popup-content h1 {
  background-color: #dae5f8;
  padding: 5px 5px 5px 5px;
  padding-left:8px;
}