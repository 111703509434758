@import './variable.scss';

.pg4-switch {
    font-size: 14px;
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.75em;
}

.pg4-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.pg4-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid $primary;
    transition: .4s;
    border-radius: 30px;
}

.pg4-switch__slider:before {
    position: absolute;
    content: "";
    height: 1.2em;
    width: 1.2em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.20em;
    background-color: $primary;
    transition: .4s;
}

input:checked+.pg4-switch__slider {
    background-color: $primary;
    border: 1px solid $primary;
}

input:focus+.pg4-switch__slider {
    box-shadow: 0 0 1px $primary;
}

input:checked+.pg4-switch__slider:before {
    transform: translateX(1.2em);
    background-color: #fff;
}