// Font path variable
$font-path: './';

// Muli Black
@font-face {
    font-family: 'Muli-Black';
    src: url('#{$font-path}muli/Muli-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

// Muli BlackItalic
@font-face {
    font-family: 'Muli-BlackItalic';
    src: url('#{$font-path}muli/Muli-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

// Muli Bold
@font-face {
    font-family: 'Muli-Bold';
    src: url('#{$font-path}muli/Muli-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

// Muli BoldItalic
@font-face {
    font-family: 'Muli-BoldItalic';
    src: url('#{$font-path}muli/Muli-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

// Muli ExtraBold
@font-face {
    font-family: 'Muli-ExtraBold';
    src: url('#{$font-path}muli/Muli-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

// Muli ExtraBoldItalic
@font-face {
    font-family: 'Muli-ExtraBoldItalic';
    src: url('#{$font-path}muli/Muli-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

// Muli ExtraLight
@font-face {
    font-family: 'Muli-ExtraLight';
    src: url('#{$font-path}muli/Muli-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

// Muli ExtraLightItalic
@font-face {
    font-family: 'Muli-ExtraLightItalic';
    src: url('#{$font-path}muli/Muli-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

// Muli Italic
@font-face {
    font-family: 'Muli-Italic';
    src: url('#{$font-path}muli/Muli-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

// Muli Light
@font-face {
    font-family: 'Muli-Light';
    src: url('#{$font-path}muli/Muli-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

// Muli LightItalic
@font-face {
    font-family: 'Muli-LightItalic';
    src: url('#{$font-path}muli/Muli-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

// Muli Regular
@font-face {
    font-family: 'Muli-Regular';
    src: url('#{$font-path}muli/Muli-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

// Muli SemiBold
@font-face {
    font-family: 'Muli-SemiBold';
    src: url('#{$font-path}muli/Muli-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

// Muli SemiBoldItalic
@font-face {
    font-family: 'Muli-SemiBoldItalic';
    src: url('#{$font-path}muli/Muli-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

// Avenir fonts
@font-face {
    font-family: 'Avenir-Black';
    src: url('#{$font-path}Avenir/Avenir Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Book';
    src: url('#{$font-path}Avenir/Avenir Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Regular';
    src: url('#{$font-path}Avenir/Avenir Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('#{$font-path}Avenir/Avenir-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Light';
    src: url('#{$font-path}Avenir/Avenir-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

.ff-ab {
    font-family: Avenir-Bold, sans-serif !important;
}

.ff-ah {
    font-family: Avenir-Heavy, sans-serif !important;
}

.ff-al {
    font-family: Avenir-Light, sans-serif !important;
}

.ff-mm {
    font-family: Muli-Medium, sans-serif !important;
}

.ff-ml {
    font-family: Muli-Light, sans-serif !important;
}