/* CSS talk bubble */
.talk-bubble {
  margin: 10px;
  display: inline-block;
  position: relative;
  height: auto;
  background-color: #14328c;
}
.border {
  border: 8px solid #14328c;
}
.round {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #14328c transparent transparent transparent;
}
.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #14328c transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #14328c #14328c transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: #14328c #14328c transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #14328c;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent #14328c;
}

/*Right triangle, placed bottom left side slightly in*/
/* .tri-right.border.btm-left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 30px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 20px solid;
	border-color: #14328c transparent transparent #14328c;
} */
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  right: -13px;
  bottom: 0px;
  bottom: -8px;
  transform: rotate(295deg);
  border-radius: 50%;
  border: 18px solid;
  border-color: #14328c transparent transparent transparent;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #14328c #14328c transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: #14328c #14328c transparent transparent;
}
/*
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #14328c;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent lightyellow;

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #14328c #14328c transparent transparent;
}
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: #14328c #14328c transparent transparent;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #14328c transparent transparent #14328c;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: #14328c transparent transparent #14328c;
}

/* Right triangle placed top right flush. */
/* .tri-right.border.right-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: -8px;
	bottom: auto;
	border: 32px solid;
	border-color: #14328c transparent transparent transparent;
}*/
.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  right: inherit;
  bottom: 0px;
  bottom: -11px;
  transform: rotate(145deg);
  border-radius: 50%;
  border: 18px solid;
  border-color: #14328c transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 12px;
  text-align: left;
  line-height: 1.5em;
  font-size: x-large;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
.App {
  text-align: center;
}
.text-box {
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: large;
  width: 90%;
  background-color: white;
  border: 1px solid #888888;
  -webkit-border-radius: 8px;
  color: #706f6f !important;
  margin-top: 6%;
}
.text-box:focus {
  outline: none !important;
}
.radio-options {
  cursor: pointer;
  height: 45px;
  background-color: white;
  border: 1px solid #c3c3c3;
  -webkit-border-radius: 16px;
  padding-top: 10px;
}
.radio-options .talktext p {
  color: #706f6f;
  font-size: 17px;
}
.radio-options .talktext {
  text-align: center;
}
.select-options {
  width: 170px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #c3c3c3;
  -webkit-border-radius: 8px;
  padding: 0%;
  min-height: 77px;
  display: table-cell;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.select-options-wrapper2{
  /* padding-bottom: 60%; */
  display: table;
  border-spacing: 10px;
  width: 100%;
}
.checkbox-options-row{
  display: table-row;
}
.question-class{
  min-height: 260px;
}
/* .question-class .select-options {
  float: left;
} */
.select-options .talktext p {
  color: #706f6f;
  font-size: 17px;
}
.select-options .talktext {
  text-align: center;
}
/* .select-options:last-child {
  width: 87%;
  min-height: 0px;
  margin: 2%;
} */
.last-option{
  width: inherit;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.last-option .talktext p {
  color: #706f6f;
  text-align: center;
  font-size: 17px;
}
.last-option .talktext {
  padding: 5px;
}
.start-button {
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  margin-left: 45%;
}
.intro {
  margin-top: 5%;
  width: 50%;
  margin-left: 25%;
}
.intro button {
  margin-left: 35%;
}
.conversation-wrapper {
  width: 29%;
  margin: 0 auto;
  position: relative;
}
.submit {
  height: 50px;
  font-size: 25px;
  font-size: 18px;
  width: 115px;
  float: right;
  cursor: pointer;
  border: 2px solid;
  background-color: #2470c2;
  margin-right: 3%;
  border-radius: 8px;
}
.answer-form {
  margin-left: 10%;
}

.select-options p {
  margin-top: -3px;
  line-height: 1.1em;
  text-align: center;
}
.radio-options p {
  margin-top: -16px;
}
.checkbox-option {
  width: 200px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid white;
  font-size: large;
  margin-left: 10%;
}
.answer-bubble {
  background-color: #2470c2;
  border: 8px solid #2470c2;
  float: right;
  padding: 1%;
}
.answer-bubble:before {
  border-color: #2470c2 transparent transparent #2470c2 !important;
}
.answer-bubble:after {
  border-color: #2470c2 transparent transparent #2470c2 !important;
}
.scroll-link {
  float: right;
  margin-right: 10%;
  background-color: #6faff3;
  width: 12px;
  height: 1.7px;
  pointer-events: none;
}
.scroll-link-wrapper {
  position: sticky;
  right: 20%;
  margin-top: 3%;
  top: 30px;
  float: right;
}
.scroll-link-wrapper .activeScroll {
  background-color: #14328c;
  height: 2.5px;
}
.talktext p {
  color: white;
}
.talk-bubble {
  color: white;
}
.talktext a {
  color: white !important;
}
.question-bubble {
  padding-top: 50px;
  word-break: break-word !important;
}
.choose-text {
  text-align: left;
  margin-left: 3%;
  margin-bottom: 0px;
  color: #706f6f;
  margin-top: 4%;
}
.selected-bg {
  background-color: #2470c2;
  border: 1px solid #2470c2;
}
.selected-bg p {
  color: white !important;
}
.scroll-links {
  margin-bottom: -20px;
}
.question-support-text {
  width: 80%;
  margin: 0 auto;
}
.question-support-text p {
  font-size: 15px;
  text-align: left;
  color: #888888;
  font-family: "Mulish" !important;
}
.options-wrapper {
  padding-left: 5%;
  margin-bottom: 25%;
  
}
.choices-wrapper{
  min-height: 350px;
}
.thanks-msg {
  text-align: center;
  font-size: 20px;
}
.review-answers-wrapper {
  margin: 3%;
}
.review-header {
  margin-bottom: 2px !important;
}
.review-question-text {
  font-weight: bold;
}
.edit-answer-link {
  color: #14328c;
  text-decoration: none;
  cursor: pointer;
}
.welcome-spinner{
  position: absolute;
  top: -100%;
  left: 45%;
}
.questionnaire-spinner {
  position: absolute;
  left: 48%;
  bottom: 62%;
}
.edit-conversation-wrapper{
  width: 80%;
}
.edit-question-model .modal-body{
  height: 90vh;
  overflow-y: scroll;
}
.edit-question-model #containerElement {
  height: 85vh;
}
.pg-text__bold{
  word-break: break-word;
}
/* .select-options {
  width: calc(50% - 20px);
} */
/* .select-options:last-child {
  width: calc(100% - 20px);
} */
#containerElement{
  padding-bottom: 100px;
}
.react-date-picker__wrapper{
  border: none;
}
.react-date-picker__inputGroup{
  padding-top: 10px;
}
.react-date-picker__button{
  padding-top: 15px;
}
.react-calendar__year-view__months__month, .react-calendar__decade-view__years__year{
  padding: 15px !important;
}
.react-calendar__century-view__decades__decade{
  padding: 0px;
}
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0em;
}
.react-date-picker{
  font-family: "Mulish" !important;
  color: #2470c2 !important;
}
.submit-wrapper{
  height: 75px;
}
.radio-p p{
  margin-bottom: 8px;
  font-family: "Mulish";
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  line-height: 32px;
}
#undefinedContainer{
  min-height: 0px !important;
}
@media only screen and (max-width: 1180px) {
  .conversation-wrapper {
    width: 35%;
  }
}
@media only screen and (max-width: 1140px) {
  .conversation-wrapper {
    width: 40%;
  }
}
@media only screen and (max-width: 991px) {
  .conversation-wrapper {
    width: 53%;
  }
  .select-options {
    width: 42%;
  }
  .select-options .talktext p {
    font-size: 15px;
    text-align: center;
  }
  .text-box {
    width: 85%;
  }
  .scroll-link-wrapper {
    display: none;
  }
  .start-button {
    height: 40px;
    width: 105px;
    font-size: 16px;
    margin-left: 36%;
  }
  .intro {
    width: 65%;
    margin-left: 20%;
    font-size: 12px;
    text-align: left;
  }
  .review-question_change {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  .conversation-wrapper {
    width: 93%;
  }
  .select-options {
    width: 42%;
  }
  .select-options .talktext p {
    font-size: 15px;
    text-align: center;
  }
  .text-box {
    width: 85%;
  }
  .scroll-link-wrapper {
    display: none;
  }
  .start-button {
    height: 40px;
    width: 105px;
    font-size: 16px;
    margin-left: 36%;
  }
  .intro {
    width: 65%;
    margin-left: 20%;
    font-size: 12px;
    text-align: left;
  }
  .review-question_change {
    font-size: 1rem;
  }
  
}
.edit-question-modal-header{
  border-bottom: none !important;
}
