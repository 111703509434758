@import './variable.scss';

.rec-list {

    &_text--container {
        background: #ACD4FF4D;
        width: 100%;
        padding: 10px 20px 10px 15px;
        border-radius: 7px;
        border: 2px solid transparent;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 7em;
        transition: box-shadow 0.2s ease, border-color 0.2s ease;
        color: #333;
    }

    &_text--container:focus,
    &_text--container:hover {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 2px rgba(255, 255, 255, 0.7);
    }

    &_text--container.selected {
        border: 2px solid $primary;
    }

    &_item {
        cursor: pointer;
        margin: 10px 0;
        display: flex;
        align-items: center;
    }

    &_text--title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 2.2em;
        font-weight: bold;
        color: #000;
    }

    &_text--body {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 4.5em;
        color: #333;
        margin: 0;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    @media (forced-colors: active) {
        &_text--container {
            forced-color-adjust: none;
            box-shadow: 0 0 0 2px ButtonText, 0 0 0 2px CanvasText;
        }

        &_text--container.selected {
            box-shadow: 0 0 0 2px Highlight, 0 0 0 2px Canvas;
        }
    }
}