@mixin pg-text--property {
    font-family: "Mulish";
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
}

.pg4-text {
    // @include pg-text--property;
    font-family: "Mulish";
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;

    &__title {
        // @include pg-text--property;
        @extend .pg4-text;
        font-size: 28px;
        font-weight: 900;
        line-height: 34px;
        text-transform: uppercase;
        letter-spacing: 3.83px;
    }

    &__heading {
        @extend .pg4-text;
        font-size: 25px;
        font-weight: 900;
        letter-spacing: 1.25px;
        line-height: 32px;
        text-transform: uppercase;
    }

    &__subheading {
        @extend .pg4-text;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 1.25px;
        line-height: 32px;
        text-transform: uppercase;
    }

    &__bold {
        @extend .pg4-text;
        font-weight: bold;
    }

    &__sm {
        @extend .pg4-text;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
    }

    &__xs {
        @extend .pg4-text;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
    }

    @media screen and (max-width:650px) {
        font-size: 14px;
        line-height: 20px;

        &__title {
            font-size: 18px;
            line-height: 22px;
        }

        &__heading {
            font-size: 16px;
            line-height: 22px;
        }

        &__subheading {
            font-size: 14px;
            line-height: 32px;
        }

        &__xs {
            @extend .pg4-text;
            font-weight: bold;
            font-size: 8px;
            line-height: 16px;
        }

    }
}