.shimmer-effect {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__item {
        height: 50px;
        margin: 5px 0;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        background-color: #9e9e9e52;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(90deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(24, 54, 143, 0.5) 50%,
                    rgba(255, 255, 255, 0) 100%);
            animation: shimmer-effect-animation 1.7s infinite;
        }
    }
}

@keyframes shimmer-effect-animation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}