/* react popup custom styling */
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-trigger__img {
  margin: 4px 6px;
  cursor: pointer;
  width: 16px;
}

.popup-content {
  z-index: 9999999 !important;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 15px rgba(0 0 0 / 20%);
  }
}

/* react popup custom styling end*/

.upload-snp {
  margin-top: 10px;
}

img {
  vertical-align: text-top !important;
}

.pg-ss {
  position: relative;
}

.pg-ss__container {
  position: relative;
  display: flex;
  /* border: 2px solid; */
}

.pg-ss__input:focus,
.pg-ss__input:active {
  outline: none;
}

.pg-ss__input {
  padding: 15px;
  width: 100%;
  outline: none;
  border-radius: 4px;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.pg-ss__list {
  z-index: 9;
  position: absolute;
  top: 50px;
  left: 0.5%;
  width: 99%;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  list-style: none;
  padding-left: 0;
  padding: 5px;
  margin: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.pg-ss__list_item {
  padding: 10px 10px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  margin: 5px 0px;
  z-index: -9999;
}

.pg-type_ahead_list_item {
  padding: 10px 10px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  margin: 5px 0px;
  z-index: -9999;
}

.pg-ss__list_item:hover {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  /* font-weight: bolder; */
}

.pg-ss-hover {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}


.break-all {
  word-break: break-all !important;
}