@import './variable.scss';

.checkbox-wrapper-18 .round {
    position: relative;
}

.checkbox-wrapper-18 .round label {
    background-color: $secondary;
    border: 2px solid $primary;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: block;
}

.checkbox-wrapper-18 .round label:after {
    border: 2px solid $secondary;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.checkbox-wrapper-18 .round input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked+label {
    background-color: $primary;
    border-color: $primary;
    margin-left: 0px;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}