@import './variable.scss';

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    // .pg-checkbox {
    // box-sizing: inherit;

    input[type=checkbox].pg-checkbox {
        --active: #14328c;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #14328c;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        width: 21px;
        border-radius: 4px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0px;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: 7px;
            top: 4px;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            transform: rotate(var(--r, 20deg));
        }

        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: .3s;
            --d-t: .6s;
            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
            --r: 43deg;
        }

        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
        }

        &:disabled:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
        }

    }

    input[type=checkbox]+label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 4px;
    }

    input[type=checkbox]:disabled+label {
        cursor: not-allowed;
    }
}

input[type=checkbox].pg-checkbox:disabled+label {
    cursor: not-allowed;
}

input[type=checkbox].pg-checkbox:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
}

input[type=checkbox].pg-checkbox:focus {
    box-shadow: 0 0 0 var(--focus);
}

// }



// .pg-checkbox-wrapper *:before,
// .pg-checkbox-wrapper *:after {
//     box-sizing: inherit;
// }