.is-typing {
  margin-bottom: 30px;
  width: 85px;
  justify-content: space-around;
  display: flex;
  margin-left: 30px;
  position: relative;
  bottom: -65px;
}
.jump1,
.jump2,
.jump3,
.jump4,
.jump5 {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: gray;
}

.jump1 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.3s;
}

.jump2 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.4s;
}

.jump3 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.5s;
}

.jump4 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.6s;
}

.jump5 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.7s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(0px);
  }

  35% {
    transform: translateY(15px);
  }

  45% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(-15px);
  }

  75% {
    background-color: white;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}
