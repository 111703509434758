[role="tooltip"].lifestyle-popup-content {
	width: fit-content !important;
	border-radius: 11px !important;
}

.popup-content {
	border: 2px solid #14328c !important;
	margin-top: -7px !important;
	color: #14328c;
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	letter-spacing: normal;
	line-height: 32px;
	text-align: left;
}

.popup-arrow {
	stroke: #14328c !important;
	stroke-dasharray: 39px !important;
	stroke-dashoffset: -58px !important;
}

.lifestyle-popup-content div:nth-of-type(1) {
	width: 32px !important;
	height: 14px !important;
}

.align-warning-icon {
	position: relative;
	bottom: 4px;
}

@media only screen and (max-width: 574px) {
	.popup-content {
		left: 0px !important;
	}
}