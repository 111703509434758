th,
td {
  padding: 10px;
}

.pg-table {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  width: 100%;
}

thead {
  background-color: #acd4ff;
}

tr th,
tr td {
  padding: 8px 10px;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

tr th {
  padding: 10px 10px;
}
/* .patient-table td:first-child {
  border-bottom-left-radius: 10px;
} */

.patient-table thead th:nth-child(1) {
  width: 35%;
}

.patient-table thead th:nth-child(2) {
  width: 55%;
}

.patient-table thead th:nth-child(3) {
  width: 2%;
  text-align: right;
}

.genotype-filter__table thead {
  z-index: 99999 !important;
  position: relative;
}

.genotype-table thead th:nth-child(1) {
  width: 20%;
}
.genotype-table thead th:nth-child(2) {
  width: 20%;
}
.genotype-table thead th:nth-child(3) {
  width: 20%;
}
.genotype-table thead th:nth-child(4) {
  width: 20%;
}
.genotype-table thead th:nth-child(5) {
  width: 20%;
  text-align: center !important;
}

/* .genotype-table tr th,
.genotype-table tr td {
  padding: 0 !important;
} */

.analysis-table td {
  vertical-align: top;
}

.analysis-table thead th:nth-child(1) {
  width: 18%;
}
.analysis-table thead th:nth-child(2) {
  width: 18%;
}
.analysis-table thead th:nth-child(3) {
  width: 18%;
}
.analysis-table thead th:nth-child(4) {
  width: 18%;
}
.analysis-table thead th:nth-child(5) {
  width: 15%;
}

.analysis-table tr:last-child {
  border-bottom: none;
}

.past-report-table thead th:nth-child(1) {
  width: 20%;
}
.past-report-table thead th:nth-child(2) {
  width: 20%;
}
.past-report-table thead th:nth-child(3) {
  width: 20%;
}
.past-report-table thead th:nth-child(4) {
  width: 20%;
}
.past-report-table thead th:nth-child(5) {
  margin: inherit auto !important;
  width: 15%;
}

.practitioners-table thead th:nth-child(1) {
  width: 3%;
}
.practitioners-table thead th:nth-child(2) {
  width: 14%;
}
.practitioners-table thead th:nth-child(3) {
  width: 14%;
}
.practitioners-table thead th:nth-child(4) {
  width: 14%;
}
.practitioners-table thead th:nth-child(5) {
  width: 14%;
}
.practitioners-table thead th:nth-child(6) {
  width: 14%;
}
.practitioners-table thead th:nth-child(7) {
  width: 14%;
}
.practitioners-table thead th:nth-child(8) {
  width: 20%;
}

/* to hide sort icon in first column */
.practitioners-table thead th:first-child span {
  display: none;
}

.admin-patient-table thead th:nth-child(1) {
  width: 2%;
}
.admin-patient-table thead th:nth-child(2) {
  width: 12%;
}
.admin-patient-table thead th:nth-child(3) {
  width: 12%;
}
.admin-patient-table thead th:nth-child(4) {
  width: 5%;
}
.admin-patient-table thead th:nth-child(5) {
  width: 10%;
}
.admin-patient-table thead th:nth-child(6) {
  width: 10%;
}
.admin-patient-table thead th:nth-child(7) {
  width: 10%;
}
.admin-patient-table thead th:nth-child(8) {
  width: 15%;
}

.admin-patient-table thead th:nth-child(9) {
  width: 29%;
}

.cookies-table thead th:nth-child(1) {
  width: 80%;
}

.cookies-table thead th:nth-child(2) {
  width: 20%;
}