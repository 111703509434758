.pg4-table-responsive thead {
    background: var(--pg_primary__p) !important;
    color: #fff !important;
}

.pg4-table-striped tbody tr:nth-of-type(2n) {
    background-color: #d4d4d480;
}


.pg4-wrapper-table-scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-bottom: 1em;
    position: relative;
    right: 50%;
    width: 100vw
}

// @media only screen and (max-width:550px) {
//     .pg4-table-responsive thead {
//         clip: rect(0 0 0 0);
//         -webkit-clip-path: inset(50%);
//         clip-path: inset(50%);
//         height: 1px;
//         overflow: hidden;
//         position: absolute;
//         width: 1px;
//         white-space: nowrap;
//     }

//     .pg4-table-responsive tr {
//         display: block
//     }

//     .pg4-table-responsive td {
//         -webkit-column-gap: 3.16vw;
//         -moz-column-gap: 3.16vw;
//         column-gap: 3.16vw;
//         display: grid;
//         grid-template-columns: 1fr 2fr;
//         text-align: left !important
//     }


//     .pg4-table-responsive tbody td:before {
//         content: attr(data-label);
//         float: left;
//         font-weight: 700;
//         padding-right: 1.78em
//     }
// }

.pg4-overflow-x-auto {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch
}

.pg4-w-100 {
    width: 100% !important;
}

// @media screen and (max-width:450px) {
//     .pg4-table-responsive td {
//         -webkit-column-gap: 3.16vw;
//         -moz-column-gap: 3.16vw;
//         column-gap: 3.16vw;
//         display: grid;
//         grid-template-columns: 2fr 2fr;
//         text-align: left !important
//     }
// }