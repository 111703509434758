.privacy {
    list-style-type: '- ';
    list-style-position: inside;
    padding-left: 15px;
}

.privacy li {
    /* padding-left: -10em; */
    text-indent: -0.8em;
    /* padding-left: 1em; */
    /* text-indent: -1.5em; */
}

.privacy li:before {
    /* content: "-"; */
    /* padding-right: 0px; */
}