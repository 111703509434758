// $primary-color: #14328c;
// $body-bg: #f2f4f5;
// @import '~bootstrap/scss/bootstrap';

:root {
    --primary: #08184a;
    --prac-primary: #14328c;
    --pat-primary: #2470c2;
    --secondary: #f2f4f5;
    --tertiary: #dde7f0;
    --border-color: rgb(170 181 214);
    --border-radius: 12px;
    --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

body {
    --primary: var(--prac-primary);
}

body.patient {
    --primary: var(--pat-primary);
}



.bg_primary {
    background: var(--primary);
}

.text_primary {
    color: var(--primary);
}

.text_secondary {
    color: var(--secondary);
}


.bg_secondary {
    background: var(--secondary);
}

.bg_tertiary {
    background: var(--tertiary);
}

$primary: var(--primary);
$pracprimary: #14328c;
$patprimary: #2470c2;
$secondary: var(--secondary);
$tertiary: var(--tertiary);
$border-radius: var(--border-radius);
$shadow: var(--box-shadow);
$border-color: var(--border-color); // .btn {
//     color: $secondary !important;
// }