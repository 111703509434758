.tablevd {
  margin: 0;
  width: 800px !important;
}

.head-vd {
  border-bottom: 1px solid #dee2e6;
}

.head-line {
  color: darkblue;
  text-align: center !important;
}

.darkblue {
  color: darkblue !important;
}

.table-size {
}

.btn-vd {
  color: #ffffff !important;
  width: 120px;
  margin: 0px;
  background-color: #14328c !important;
}

.red {
  color: #ea1a1a !important;
}

.green {
  color: #008a00 !important;
}

.tablediv {
  display: flex;
  justify-content: center;
}

thead {
    background-color: blue;
    color: white;
} 

/*  Virtual Dispensary */

.vd-setup-bg{

  background-color:#e4e8f1;
  margin-top: -15px;
  
  }
  
  .vd-subheading{
    letter-spacing: 1px;

  }
  
  .vd-text-primary{
  
  color: #14328c !important;
  }
  

  .vd-text__sm{
      line-height: 22px;
  }
  
  
  .vd-text__md{
      color: #000;
  }
  
  .vd-text__xx{
      color: #000;
  
  }
  .vd-vd-btn{
  text-transform: uppercase;
      width: 100%;
      border-radius: 8px;
      border: none;
      color: white;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      letter-spacing: 2px;
      font-size: 12px;
      padding: 18px 9px;
      background-color: #14328c !important;}

      .vd-vd-grey{
        text-transform: uppercase;
            width: 100%;
            border-radius: 8px;
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            letter-spacing: 2px;
            font-size: 12px;
            padding: 18px 9px;
            background-color: #6e7075  !important;}
  
  .status-icon{
  width:30px
  }
  
  .vd-text-bold{

  }
   
  .vd-text-green{
  color: #35ce0c;
  }
  
  
  .vd-text-normal{
      color: #000;
  }
  .vd-text-normal span{
  }
  .vd-btn{
      letter-spacing: 2.58px;
      min-height: 58px;
      word-break: break-word;
      border-radius: 8px;
      background:#14328C;
      color: #ffffff;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      height: fit-content;
  }
  
  
  .vd-circle {
    height: 52px;
    border-radius: 50%;
    text-align: center;
    background-color: #2470c2;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 85px;
    min-width: 56px;
    margin-right: 10px;
    font-weight: 700;
  }

  .pl-15p{
    padding-left: 17% !important;
  }