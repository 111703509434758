$primary: #14328c; // Replace this with your actual primary color

.pg-radio {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 25px;
    height: 24px;
    border: 2px solid #B8B8B8;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    margin-right: 0.3em;
    vertical-align: middle;
    transition: all 250ms ease;
    aspect-ratio: 1 / 1;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: transparent;
        transition: all 250ms ease;
    }

    &:checked {
        &::after {
            background-color: $primary; // Inner filled circle
        }
    }

    // &:focus {
    //     border: 1.5px solid $primary !important;
    //     // outline-offset: 2px;
    // }

    &:disabled {
        border-color: lighten($primary, 40%);
        cursor: not-allowed;

        &::after {
            background-color: lighten($primary, 40%);
        }
    }
}